import React from "react";
import { Accordion } from "react-accessible-accordion";

import { VillageContactsInRegions } from "@state/types";
import { Section, PageWidth } from "@util/standard";
import VillageContactCards from "./contactCards";

interface Props {
  data: VillageContactsInRegions[];
}

export default function AllVillageContactsInRegions({ data }: Props) {
  if (!Boolean(data?.length)) return null;

  return (
    <Section aria-label="Village contacts">
      <PageWidth>
        <Accordion allowZeroExpanded>
          {data.map(village => {
            if (village == null) return null;
            const { contacts, region } = village;

            return <VillageContactCards key={region.title} data={contacts} region={region} />;
          })}
        </Accordion>
      </PageWidth>
    </Section>
  );
}
