import React from "react";
import { graphql, PageProps } from "gatsby";

import { Query } from "@graphql-types";
import { ErrorMsg, Header, SEO } from "@shared";
import { usePageMeta } from "@util/logicHooks";
import { BasicHero } from "@shared";
import { ContactVillageLandingPageContext } from "@state/types";
import { EnquiryContainer, EnquiryDetails, EnquiryColumn } from "./contactTemplate";
import { PageWidth } from "@util/standard";
import ContactCta from "@components/contactCta";
import AllVillageContactsInRegions from "@components/village/allVillageContactsInRegions";

type PageContext = PageProps & ContactVillageLandingPageContext;
interface Props {
  data: Query;
  pageContext: PageContext;
}

export default function ContactVillageLandingTemplate({
  data: { sanityContactVillageLanding, sanityHeader },
  pageContext: { villageContactsInRegions },
}: Props) {
  if (sanityContactVillageLanding == null)
    return <ErrorMsg data={sanityContactVillageLanding} msg="Error fetching data for page" />;

  const {
    slug,
    _type,
    pageColour,
    title,
    seo,
    blockContent,
    ctas,
    enquiryContacts,
    ctaTitle,
    contactCtas,
  } = sanityContactVillageLanding;
  usePageMeta(pageColour?.colour?.hex, _type);

  return (
    <div>
      <SEO seoData={seo} slug={slug?.current as string} />
      <Header data={sanityHeader} />
      <BasicHero heading={title} blockContent={blockContent} ctas={ctas} />
      <AllVillageContactsInRegions data={villageContactsInRegions} />

      <PageWidth>
        <EnquiryContainer>
          {enquiryContacts &&
            enquiryContacts.map((contact: any, index: any) => (
              <EnquiryColumn overflow="hidden" key={index + contact.title}>
                <h3>{contact.title}</h3>
                <EnquiryDetails contact={contact} />
              </EnquiryColumn>
            ))}
        </EnquiryContainer>
      </PageWidth>

      {contactCtas && <ContactCta title={ctaTitle} data={contactCtas} />}
    </div>
  );
}

export const query = graphql`
  query villageContactLandingQuery {
    sanityHeader {
      ...sanityHeader
    }
    sanityContactVillageLanding {
      _type
      title
      blockContent {
        _rawBlockContent
      }
      ctas {
        ...sanityLink
      }
      slug {
        current
      }
      pageColour {
        ...sanityColorPicker
      }
      ctaTitle
      contactCtas {
        ...sanityContactCta
      }
      enquiryContacts {
        ...sanityContactCta
      }
    }
  }
`;
