import React from "react";
import styled from "styled-components";

import BookATourForm from "@components/forms/bookATourForm";
import { ContactForm } from "@components/forms/contactForm";
import {
  Maybe,
  Query,
  SanityBlockContent,
  SanityBookatour,
  SanityContactCta,
  SanityContactPage,
  SanityFeedbackPage,
} from "@graphql-types";
import { BasicHero, ErrorMsg, Header, SEO } from "@shared";
import { mediaQuery } from "@util/constants";
import { usePageMeta } from "@util/logicHooks";
import { Container, P, PageWidth, Section } from "@util/standard";
import ContactCta from "@components/contactCta";

interface Props {
  data: Query;
  hero: Maybe<SanityBlockContent> | undefined;
  title: Maybe<string> | undefined;
  formType: string;
}

const ContactTemplate = ({
  data: {
    sanityContactPage,
    sanityFeedbackPage,
    sanityHeader,
    allSanityRegion,
    allSanityVillage,
    sanityBookatour,
  },
  hero,
  title,
  formType,
}: Props) => {
  const returnPageData = (
    sanityFeedbackPage: Maybe<SanityFeedbackPage> | undefined,
    sanityContactPage: Maybe<SanityContactPage> | undefined,
    sanityBookatour: Maybe<SanityBookatour> | undefined,
  ) => {
    if (sanityContactPage) {
      return sanityContactPage;
    }
    if (sanityBookatour) {
      return sanityBookatour;
    }
    if (sanityFeedbackPage) {
      return sanityFeedbackPage;
    }

    return null;
  };
  const pageData: Maybe<SanityContactPage | SanityBookatour | SanityFeedbackPage> | undefined =
    returnPageData(sanityFeedbackPage, sanityContactPage, sanityBookatour);

  if (pageData == null) {
    return <ErrorMsg data={sanityContactPage} msg="Error fetching data for page" />;
  }

  const { seo, pageColour, ctaTitle, contactCtas, enquiryContacts, _type, ctas, form } = pageData;
  usePageMeta(pageColour?.colour?.hex, _type);

  return (
    <div>
      <SEO seoData={seo} slug="contact" />
      <Header data={sanityHeader} />
      <BasicHero heading={title} blockContent={hero} ctas={ctas} />
      <Section aria-label="Contact Metlifecare" marginOverride="20px 0px">
        {formType === "bookatour" ? (
          <BookATourForm regions={allSanityRegion.nodes} villages={allSanityVillage.nodes} />
        ) : (
          <ContactForm generalContact />
        )}
      </Section>

      <Section aria-label="Contact Details" marginOverride="50px 0px 50px 0">
        <PageWidth>
          <EnquiryContainer>
            {enquiryContacts &&
              enquiryContacts.map((contact: any, index: any) => (
                <EnquiryColumn overflow="hidden" key={index + contact.title}>
                  <h3>{contact.title}</h3>
                  <EnquiryDetails contact={contact} />
                </EnquiryColumn>
              ))}
          </EnquiryContainer>
        </PageWidth>
      </Section>
      {contactCtas && <ContactCta title={ctaTitle} data={contactCtas} paddingOverride="50px 0" />}
    </div>
  );
};

export default ContactTemplate;

interface DetailsProps {
  contact: SanityContactCta;
}

export const EnquiryDetails = ({ contact }: DetailsProps) => {
  const { phone, email } = contact;

  const StyledDetails = styled(Container)`
    ${mediaQuery.tabletDown} {
      p {
        font-size: 16px;
      }
    }
  `;

  return (
    <StyledDetails flexDirection="column" margin="0 0 20px 0">
      {email && (
        <>
          <P fontSize={26} fontWeight="bold">
            {`Email: `}
            <a href={`mailto:${email}`}>{email}</a>
          </P>
        </>
      )}
      {phone && (
        <>
          <P fontSize={26} fontWeight="bold">
            {`For enquiries phone: `}
            <a href={`tel:${phone}`}>{phone}</a>
          </P>
        </>
      )}
    </StyledDetails>
  );
};

export const EnquiryContainer = styled(Container)`
  justify-content: flex-start;
  flex-flow: wrap;
  margin: 0px 0 0 0;
  width: 80%;
  column-gap: 100px;

  ${mediaQuery.tabletDown} {
    row-gap: 40px;
    margin: 0px;
    width: 100%;
  }
`;

export const EnquiryColumn = styled(Container)`
  flex-direction: column;

  h3 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  p {
    margin: 0;
    white-space: pre-wrap;
  }
  strong {
    margin-bottom: 10px;
  }
`;
