import React from "react";
import styled from "styled-components";
import {
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

import { Container, CreamCardContainer } from "@util/standard";
import { assets, colors, mediaQuery, villageSubPagePaths } from "@util/constants";
import { SanityRegion, SanityVillage } from "@graphql-types";
import { Details } from "./contactDetails";
import { formatAddress } from "@util/helper";
import { Link } from "@global";

interface Props {
  data: SanityVillage[];
  region?: SanityRegion;
}

export default function VillageContactCards({ data, region }: Props) {
  if (!Boolean(data?.length)) return null;

  return (
    <AccordionItem>
      <AccordionItemHeading>
        <AccordionItemButton>
          <TitleContainer>
            <h2>{region?.title}</h2>
            <img src={assets.chervon} />
          </TitleContainer>
        </AccordionItemButton>
      </AccordionItemHeading>
      <AccordionItemPanel>
        <Container
          width="100%"
          flexDirection="column"
          columnGap="25px"
          rowGap="25px"
          margin="0 0 40px 0"
        >
          {data.map((village, index) => {
            if (village == null) return null;
            const { title, receptionContact, careHomeContacts, slug, region } = village;
            const { string, url } = formatAddress(village);

            const villageUrl = {
              slug: {
                current: `${region?.slug?.current}/${
                  villageSubPagePaths(slug?.current ?? "").contact.slug.current
                }`,
              },
            };

            return (
              <CreamCardContainer key={index}>
                <Container className="wrapper">
                  <Container flexDirection="column" tabletWidth="100%" width="50%">
                    <h3>{title}</h3>
                    <a target="_blank" href={url}>
                      {string}
                    </a>
                    {slug?.current && (
                      <ViewVillageContact
                        linkText="More contact options"
                        internalLink={
                          villageSubPagePaths(`${region?.slug?.current}/${slug.current}`).contact
                        }
                      />
                    )}
                  </Container>
                  <ContactContainer>
                    {receptionContact && (
                      <Details
                        title="Reception"
                        contact={receptionContact}
                        villageTitle={village.title}
                      />
                    )}
                    {careHomeContacts && careHomeContacts.length > 0 && (
                      <Details
                        title="Care home"
                        contact={careHomeContacts[0]}
                        villageTitle={village.title}
                      />
                    )}
                  </ContactContainer>
                </Container>
              </CreamCardContainer>
            );
          })}
        </Container>
      </AccordionItemPanel>
    </AccordionItem>
  );
}

const TitleContainer = styled(Container)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-top: 2px solid ${colors.navy};
  padding: 40px 0;
  cursor: pointer;
  img {
    width: 20px;
  }
  button {
    margin: 0 0 0 15px;
  }
`;

const ViewVillageContact = styled(Link)`
  text-decoration: underline;
  font-weight: bold;
  ${mediaQuery.tabletDown} {
    margin-bottom: 20px;
  }
`;

const ContactContainer = styled(Container)`
  width: 50%;
  flex-wrap: wrap;
  a {
    word-break: break-all;
  }

  justify-content: space-between;
  column-gap: 50px;

  ${mediaQuery.tabletDown} {
    width: 100%;
    justify-content: normal;
    flex-direction: column;
  }

  ${mediaQuery.mobileDown} {
  }
`;
