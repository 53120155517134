import React from "react";
import styled from "styled-components";

import { Maybe, SanityContactCta } from "@graphql-types";
import { Section, PageWidth, PreviewCard, Container, P, GiveMeSomeSpace } from "@util/standard";
import { Link } from "@global";
import { useStore } from "@state/store";
import { mediaQuery } from "@util/constants";

interface Props {
  title: Maybe<string> | undefined;
  data: Maybe<SanityContactCta>[];
  paddingOverride?: string;
}

export default function ContactCta({ data, title, paddingOverride }: Props) {
  if (data == null || data.length === 0) return null;

  const { pageColor } = useStore();

  return (
    <Section aria-label="Alternative contact details" backgroundColor="cream" marginOverride="0px">
      <PageWidth>
        <StyledCardContainer
          columnGap="25px"
          padding={paddingOverride ? paddingOverride : "150px 0px"}
          isTabletColumn
        >
          <PreviewCard
            textColor="navy"
            backgroundColor="white"
            isGuide={false}
            overrideMobileHeight="fit-content"
          >
            <h3>{title}</h3>
          </PreviewCard>
          {data.map((contact: any, index: number) => {
            return (
              <PreviewCard
                textColor="white"
                backgroundColor="navy"
                isGuide={false}
                overrideMobileHeight="fit-content"
                key={index + contact.title}
              >
                <CardTextWrapper flexDirection="column">
                  <h3>{contact.title}</h3>
                  <P margin="10px 0 0 0">{contact.contactName}</P>
                  <a className="contact-link" href={`tel:${contact.phone}`}>
                    {contact.phone}
                  </a>
                  <a className="contact-link" href={`mailto:${contact.email}`}>
                    {contact.email}
                  </a>
                  <GiveMeSomeSpace space={5} />
                </CardTextWrapper>
                {contact.link && (
                  <Link {...contact.link} colorvalue={pageColor} className="button-white" />
                )}
              </PreviewCard>
            );
          })}
        </StyledCardContainer>
      </PageWidth>
    </Section>
  );
}

const StyledCardContainer = styled(Container)`
  ${mediaQuery.tabletDown} {
    row-gap: 15px;

    flex-direction: row;
    flex-wrap: wrap;
    padding: 70px 0;
  }
`;

const CardTextWrapper = styled(Container)`
  ${mediaQuery.mobileDown} {
    h3 {
      margin-bottom: 10px;
    }
    p,
    a {
      margin: 0;
    }
  }
`;
