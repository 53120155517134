import React from "react";
import styled from "styled-components";

import { SanityVillage, SanityContactPerson, Maybe } from "@graphql-types";
import { Container, PageWidth, Section as StandardSection } from "@util/standard";
import { colors, mediaQuery } from "@util/constants";
import { formatAddress } from "@util/helper";

interface Props {
  data: SanityVillage;
}

export default function ContactDetails({ data }: Props) {
  const {
    receptionContact,
    salesEnquiryContacts,
    careHomeContacts,
    street,
    suburb,
    city,
    postCode,
    filters,
    title,
  } = data;
  const { url } = formatAddress(data);

  return (
    <Section noMargins aria-label="Contact details">
      <PageWidth>
        {/* TEMPORARILY HIDDEN */}
        {/* {Boolean(filters?.livingOptions?.length) && (
          <LivingOptionsContainer>
            {filters?.livingOptions?.map((livingOption, index) => {
              if (livingOption == null || livingOption.title == null) return null;
              const { title, subFilter } = livingOption;

              return (
                <div key={index + title}>
                  <InlineTag isNavyTag>{title}</InlineTag>
                  {subFilter && <InlineTag>{subFilter}</InlineTag>}
                </div>
              );
            })}
          </LivingOptionsContainer>
        )} */}
        <ContentContainer>
          {receptionContact && (
            <ContentColumn>
              <h3>Village contact</h3>
              <AddressLink target="_blank" href={url}>
                <span>{street}</span>
                <span>
                  {suburb}, {city} {postCode}
                </span>
              </AddressLink>
              <Details title="Reception" contact={receptionContact} villageTitle={title} />
            </ContentColumn>
          )}
          {Boolean(salesEnquiryContacts?.length) && (
            <ContentColumn>
              <h3>Sales</h3>
              {salesEnquiryContacts?.map((contact, index) => {
                if (contact == null) return null;

                return (
                  <Details
                    key={index}
                    title={contact.name}
                    contact={contact}
                    villageTitle={title}
                  />
                );
              })}
            </ContentColumn>
          )}
          {Boolean(careHomeContacts?.length) && (
            <ContentColumn>
              <h3>Care home</h3>
              {careHomeContacts?.map((contact, index) => {
                if (contact == null) return null;

                return (
                  <Details
                    title={contact.name}
                    contact={contact}
                    key={index}
                    villageTitle={title}
                  />
                );
              })}
            </ContentColumn>
          )}
        </ContentContainer>
      </PageWidth>
    </Section>
  );
}

interface DetailsProps {
  title: Maybe<string> | undefined;
  contact: Maybe<SanityContactPerson>;
  villageTitle?: Maybe<string> | undefined;
}

export const Details = ({ title, contact, villageTitle }: DetailsProps) => {
  if (contact == null) return null;
  const { name, phone, email, hours, weekendHours } = contact;

  return (
    <Container flexDirection="column" margin="0 0 20px 0" width="100%" tabletWidth="100%">
      {(title || name) && <strong>{title ?? name}</strong>}
      {phone && (
        <a href={`tel:${phone}`} data-villagename={villageTitle}>
          {phone}
        </a>
      )}
      {hours && <span>Hours: {hours}</span>}
      {weekendHours && (
        <span>
          Weekends {"&"} Public Holidays: {weekendHours}
        </span>
      )}

      {email && (
        <a href={`mailto:${email}`} data-villagename={villageTitle}>
          {email}
        </a>
      )}
    </Container>
  );
};

const Section = styled(StandardSection)`
  background-color: ${colors.cream};

  .container {
    ${mediaQuery.mobileDown} {
      flex-direction: column;
    }
  }
`;

const ContentContainer = styled(Container)`
  justify-content: space-between;
  flex-flow: wrap;
  margin: 68px 0 120px 0;
  width: 80%;
`;

const LivingOptionsContainer = styled(Container)`
  margin: 60px 0 0 0;
  column-gap: 15px;
  row-gap: 15px;

  ${mediaQuery.tabletDown} {
    flex-direction: column;
    justify-content: flex-start;
  }
`;

const ContentColumn = styled(Container)`
  flex-direction: column;

  h3 {
    font-size: 28px;
    margin-bottom: 20px;
  }
  p {
    margin: 0;
    white-space: break-spaces;
  }
  strong {
    margin-bottom: 10px;
  }
`;

const AddressLink = styled.a`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  cursor: pointer;
`;
